import React from "react"

import { EmailIcon, LockIcon, OpenEyeIcon } from "../../assets/icons"
import defaultLocale from "../../defaultLocal"

const LOGIN_FIELDS_IDS = {
  EMAIL: 'email',
  PASSWORD: 'password'
}

export const getFormFields = ({ isPasswordShown, setIsPasswordShown }) => {
  const { login: { emailFieldLabel, emailFieldPlaceholder, passwordFieldLabel, passwordFieldPlaceholder, } } = defaultLocale

  return ([
    { key: LOGIN_FIELDS_IDS.EMAIL,
      htmlFor: LOGIN_FIELDS_IDS.EMAIL,
      label: emailFieldLabel,
      placeholder: emailFieldPlaceholder,
      type: 'text',
      prefixIcon: EmailIcon,
      validation: {
        required: true,
      }
    },
    { key: LOGIN_FIELDS_IDS.PASSWORD,
      htmlFor: LOGIN_FIELDS_IDS.PASSWORD,
      label: passwordFieldLabel,
      placeholder: passwordFieldPlaceholder,
      type: (isPasswordShown? 'text' : 'password'),
      renderPostfixElement: () => (
        <span className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer" onClick={() => setIsPasswordShown((prevState) => !prevState)}>
          <OpenEyeIcon stroke={isPasswordShown? '#2A272B': "#AAA8AA"} />
        </span>
      ),
      prefixIcon: LockIcon,
      validation: {
        required: true,
      }
    }
  ])}

export const RedirectToLogin = () => {
  // eslint-disable-next-line no-undef
  const fincartBaseUrl = process.env.REACT_APP_MERCHANT_URL;
  window.location.replace(fincartBaseUrl + '/login')
  return <></>
}

export const RedirectToGetStarted = () => {
  // eslint-disable-next-line no-undef
  const fincartBaseUrl = process.env.REACT_APP_MERCHANT_URL;
  window.location.replace(fincartBaseUrl + '/get-started')
  return <></>
}
