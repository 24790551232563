import React from "react";

const GetStartedButton = ({ text = '' }) => {
  
  // eslint-disable-next-line no-undef
  const fincartBaseUrl = process.env.REACT_APP_MERCHANT_URL;
  
  const onButtonClick = (e) => {
    e.target.blur()
    window.location.replace(fincartBaseUrl + '/get-started', '_blank')
  }
  return (
    <button
      type="button"
      className="text-white font-Poppins-SemiBold bg-blue hover:bg-dark-blue focus:shadow-button text-sm rounded-full w-fit px-4 py-[7px] text-center"
      onClick={onButtonClick}
    >
      {text}
    </button>
  )
};

export default GetStartedButton;