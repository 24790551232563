import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './components/layout';
import Content from './components/content';
import Logout from "./components/logout";
import { RedirectToGetStarted, RedirectToLogin } from "./components/login/utils";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Content />} />
          <Route path="/get-started" element={<RedirectToGetStarted />} />
          <Route path="/login" element={<RedirectToLogin />} />
          <Route path="/logout" element={<Logout />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App